.program-tour {
  &__title {
    position: relative;
    z-index: 1;
    @include cv900;
    font-size: 47px;
    line-height: 58px;
    text-align: center;
    letter-spacing: 0.12em;
    font-stretch: 270%;

    &::before {
      content: '';
      display: block;
      width: 515px;
      height: 100px;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -1;
      transform: translate(-50%, -50%);
      background-color: #d9e5f0;
      clip-path: polygon(0 5%, 100% 0, 98% 95%, 5% 100%);
    }
  }
}

.days-list {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.day-item {
  display: flex;
  gap: 48px;

  &__header {
    width: 485px;
    height: 390px;
    flex-shrink: 0;
    position: relative;
    z-index: 1;

    &::after {
      content: '';
      display: block;
      width: calc(100% + 1px);
      height: 31px;
      position: absolute;
      bottom: -1px;
      left: 0;
      z-index: -1;
      background-color: #e8eff6;
    }
  }

  &:nth-child(odd) .day-item__header {
    &::after {
      clip-path: polygon(0 100%, 100% 0, 100% 100%);
    }
  }

  &:nth-child(even) .day-item__header {
    &::after {
      clip-path: polygon(0 0, 100% 100%, 0 100%);
    }
  }

  &__number {
    padding: 6px 20px 9px;
    min-width: 80px;
    height: 60px;
    position: absolute;
    top: 28px;
    left: 26px;
    @include cv900;
    font-size: 37px;
    line-height: 46px;
    letter-spacing: 0.12em;
    color: #ffffff;
    font-stretch: 270%;
    z-index: 1;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -1;
      transform: translate(-50%, -50%);
      background-color: #db2947;
      clip-path: polygon(0 0, 100% 7%, 100% 100%, 0 95%);
    }
  }

  &__content {
    padding-top: 12px;
  }

  &__image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    object-fit: cover;
  }

  &__title {
    @include cv900;
    font-size: 37px;
    line-height: 46px;
    letter-spacing: 0.12em;
    font-stretch: 270%;
  }

  &__subtitle {
    padding-top: 12px;
    font-size: 18px;
    line-height: 24px;
    color: #94a5b4;
  }

  &__timeline {
    padding-top: 24px;
    font-size: 18px;
    line-height: 24px;
  }
}

@media (max-width: 1123px) {
  .program-tour {
    &__title {
      font-size: 24px;
      line-height: 32px;
      font-stretch: 300%;

      &::before {
        width: 250px;
        height: 70px;
        clip-path: polygon(0 5%, 100% 0, 98% 95%, 5% 100%);
      }
    }

    .days-list {
      padding-top: 50px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 30px;
    }

    .day-item {
      flex-direction: column;
      gap: 0;

      &__header {
        width: 100%;
        height: 280px;
      }

      &__number {
        padding: 6px 12px 8px;
        min-width: 80px;
        height: 40px;
        top: 16px;
        left: 16px;
        font-size: 18px;
        line-height: 26px;

        &::before {
          clip-path: polygon(0 0, 100% 7%, 100% 100%, 0 95%);
        }
      }

      &__content {
        padding-top: 15px;
      }

      &__title {
        font-size: 20px;
        line-height: 26px;
      }

      &__subtitle {
        padding-top: 4px;
        font-size: 16px;
        line-height: 24px;
      }

      &__timeline {
        padding-top: 12px;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

@media (max-width: 850px) {
  .program-tour {
    .days-list {
      display: flex;
    }
  }
}
