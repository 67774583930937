.path-tour-map {
  margin-top: 100px;
  width: 100%;
  height: 770px;
  position: relative;
  z-index: 1;
  background-color: #ffffff;
  overflow: hidden;

  &::before {
    content: '';
    width: 100%;
    height: 10%;
    position: absolute;
    bottom: -1px;
    left: 0;
    z-index: 1;
    background-color: #ffffff;
    clip-path: polygon(0 100%, 100% 0, 100% 100%);
  }

  &__toggle-active {
    padding: 11px 12.5px;
    min-width: 225px;
    position: absolute;
    left: 50%;
    bottom: 100px;
    transform: translateX(-50%);
    border: none;
    background-color: #ffffff;

    span {
      display: inline-block;
      width: 100%;
      padding: 15px 16px;
      background-color: #db2947;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.64px;
      text-transform: uppercase;
      color: #ffffff;
      white-space: nowrap;
    }

    &.active span {
      background-color: #2b4761;
    }
  }

  .ol-overlay-container {
    top: 0;
  }
}

@media (max-width: 1123px) {
  .path-tour-map {
    margin-top: 50px;
    height: 640px;

    &__toggle-active {
      padding: 10px;
      min-width: 270px;
      bottom: 80px;

      span {
        padding: 13px 50px;
        font-size: 14px;
      }
    }

    .ol-overlay-container {
      top: 5px;
    }
  }
}
