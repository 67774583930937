.dropdown {
  position: relative;
  display: inline-block;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  &__arrow {
    margin-left: 10px;
    transition: transform 0.3s ease;
  }

  &__arrow.up {
    transform: rotate(180deg);
  }

  &__arrow.down {
    transform: rotate(0deg);
  }

  &__menu {
    display: flex;
    flex-direction: column;
    gap: 14px;
    margin-top: 8px;
    position: absolute;
    top: 100%;
    left: 0;
    padding: 16px 24px 24px 16px;
    list-style: none;
    z-index: 1000;
    background-color: var(--dropdown-bg-color);
  }

  &__item {
    white-space: nowrap;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: relative;
    color: inherit;

    &::before {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 0;
      height: 2px;
      background-color: transparent;
      transition: all 0.3s ease-in-out;
    }

    &.active {
      &::before {
        width: 100%;
        background-color: #DB2947FF;
      }
    }

    &:hover::before {
      width: 100%;
      background-color: #DB2947FF;
    }
  }
}

